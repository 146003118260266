import { useState, useEffect } from 'react';
import './App.css';
import ScanDocument from "./screens/ScanDocument";
import Result from "./screens/Result";
import { isTokenValid, getParams } from './lib/utils';
import ErrorScreen from './screens/ErrorScreen';

function App() {
  const [data, setData] = useState(null);
  const [images, setImages] = useState(null);
  const [openFromIframe, setOpenFromIframe] = useState(false);
  const [error, setError] = useState({ error: false, code: 0, reason: 'success' });
  const [accessToken, setAccessToken] = useState(null);
  const [contractId, setContractId] = useState(0);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    window === window.parent
      ? setOpenFromIframe(false)
      : setOpenFromIframe(true);
    // Get params
    let params = getParams(window.location.search)
    let condition = params.get("accessToken") && params.get("userId") && params.get("contractId")

    !condition && setError({ error: true, code: 1, reason: 'Alguno de los datos necesarios no existe, es nulo o no tiene valor' })
    setAccessToken(params.get("accessToken"))
    setContractId(params.get("contractId"))
    setUserId(params.get("userId"))
    try {
      const token = JSON.parse(window.atob(params.get("accessToken").split('.')[1]))
      let tokenCondition = isTokenValid(token);
      !tokenCondition && setError({ error: true, code: 1, reason: 'Token expirado' })
    } catch (e) {
      e.code === 5 && setError({ error: true, code: 1, reason: 'El token proporcionado es invalido' })
    }
  }, []);

  return (
    <div className={!openFromIframe ?
      "w-full h-full flex items-center justify-center bg-gray-50 dark:bg-gray-700" :
      "w-full h-full flex items-center justify-center bg-gray-50 dark:bg-gray-700 inset-0 bg-gray-600 bg-opacity-60 overflow-y-auto"}>
      {error.error ? <ErrorScreen error={error} /> :
        data ? (
          <Result 
          setData={setData}
          data={data}
          images={images}
          accessToken={accessToken}
          contractId={contractId}
          userId={userId} />
        ) : (
          <ScanDocument setData={setData} setImages={setImages} />
        )}
    </div>
  );
}

export default App;
