import axios from "axios";

const idrndRequest = async (args) => {
    let formdata = new FormData();
    formdata.append("contractId", args.contractId);
    formdata.append("userId", args.userId)
    formdata.append("file", args.frontImg)    
    formdata.append("backImage", args.croppedBackImg)
    formdata.append("country", args.countryCode)
    formdata.append("documentType", args.documentType)

    if(args.barcodeResultData) {
        formdata.append("barcodeResultData", args.barcodeResultData)
    }


    let config = {
        headers: { Authorization: `Bearer ${args.jwtToken}` }
    }

    let response = await axios.post(`${process.env.REACT_APP_BE_API_URL}/idrnd_doc`, formdata, config)
    let result = await response.data;
    return result;
};

export default idrndRequest;