import React, { useRef, useEffect } from "react";
import {
  applyPolyfills,
  defineCustomElements,
} from "@microblink/blinkid-in-browser-sdk/ui/loader";
import { getFirstNonEmptyValue, sendMessage } from "../lib/utils";
import { getCameraDevices } from "@microblink/blinkid-in-browser-sdk";

function DocumentScanner({ setData, setImages }) {
  const el = useRef(null);

  useEffect(() => {
    applyPolyfills().then(() => {
      defineCustomElements().then(() => {
        // el.current.licenseKey = process.env.REACT_APP_MICROBLINK_KEY_ON_WEB;
        // el.current.licenseKey = process.env.REACT_APP_MICROBLINK_KEY_LOCAL;
        el.current.licenseKey = process.env.REACT_APP_MICROBLINK_KEY_ON_WEB_NO_AWS;
        el.current.recognizers = ["BlinkIdMultiSideRecognizer"];
        el.current.recognizerOptions = {
          BlinkIdMultiSideRecognizer: {
            returnFullDocumentImage: true,
            returnEncodedFullDocumentImage: true,
            fullDocumentImageDpi: 400,
            saveCameraFrames: true,
            paddingEdge: 0.02,
            returnSignatureImage: true,
            returnFaceImage: true,
            // "includeSuccessFrame": true
          },
        };
        el.current.saveCameraFrames = true;

        // Engine location depends on the actual location of WebAssembly resources
        el.current.engineLocation = window.location.origin + "/resources";
        el.current.allowHelloMessage = false;
        el.current.scanFromImage = false;
        el.current.includeSuccessFrame = true;
        el.current.iconCameraScanningCompleted =
          "data:image/svg+xml;base64,PHN2ZyBpZD0ic3ZnIiB2aWV3Qm94PSIxNSAyMCAxNjUgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHRleHQgeD0iMjAiIHk9IjM1IiBjbGFzcz0ic21hbGwiPkVzcGVyZSB1biBtb21lbnRvLi4uPC90ZXh0Pgo8L3N2Zz4=";
        // Aqui se puede usar internalizacion como i18n
        // i18n requiere instalar librerias extras
        el.current.allowHelpScreensOnboarding = false;
        el.current.translations = {
          /* Pantallas de ayuda */
          "help-button-lobby-tooltip": "¿Necesitas ayuda?",
          "help-button-back": "Atrás",
          "help-button-next": "Siguiente",
          "help-button-done": "Hecho",
          "help-button-start-scanning": "Comenzar escaneo",
          "help-doc-valid-title": "Mantén todos los detalles visibles",
          "help-doc-valid-description":
            "Asegúrate de que el documento esté bien iluminado. Todos los campos del documento deben ser visibles en la pantalla de la cámara.",
          "help-doc-invalid-invisible-fields-title": "Mantén todos los detalles visibles",
          "help-doc-invalid-invisible-fields-description":
            "Asegúrate de no estar cubriendo partes del documento con un dedo, incluidos los márgenes inferiores. Además, ten cuidado con los reflejos del holograma que pasan por encima de los campos del documento.",
          "help-doc-invalid-harsh-light-title": "Cuidado con la luz intensa",
          "help-doc-invalid-harsh-light-description":
            "Evita la luz directa e intensa, ya que se refleja en el documento y puede hacer que partes del documento no sean legibles. Si no puedes leer los datos del documento, tampoco serán visibles para la cámara.",
          "help-doc-invalid-to-much-motion-title": "Mantente quieto mientras escaneas",
          "help-doc-invalid-to-much-motion-description":
            "Intenta mantener el teléfono y el documento quietos mientras escaneas. Cualquier movimiento puede desenfocar la imagen y hacer que los datos del documento no sean legibles.",
        
          "action-alt-camera": "Cámara del dispositivo",
          "action-alt-gallery": "Desde la galería",
          "action-message": "Escanea o elige desde la galería",
          "action-message-camera": "Cámara del dispositivo",
          "action-message-camera-disabled": "Cámara desactivada",
          "action-message-camera-not-allowed": "Cámara no permitida",
          "action-message-camera-in-use": "Cámara en uso",
          "action-message-image": "Desde la galería",
          "action-message-image-not-supported": "No soportado",
          "camera-disabled": "Cámara desactivada",
          "camera-not-allowed": "No se puede acceder a la cámara.",
          "camera-in-use": "La cámara ya está siendo utilizada por otra aplicación.",
          "camera-generic-error": "No se puede acceder a la cámara.",
          "camera-feedback-scan-front": ["Escanea el lado frontal", "del documento"],
          "camera-feedback-scan-back": ["Escanea el lado posterior", "del documento"],
          "camera-feedback-flip": "Gira al lado posterior",
          "camera-feedback-barcode-message": "Escanea el código de barras",
          "camera-feedback-move-farther": "Aléjate más",
          "camera-feedback-move-closer": "Acércate más",
          "camera-feedback-adjust-angle": "Ajusta el ángulo",
          "camera-feedback-blur": "Mantente quieto",
          "camera-feedback-glare": "Inclina o mueve el documento para quitar los reflejos",
          "camera-feedback-wrong-side": "Gira el documento",
          "camera-feedback-face-photo-covered": "Mantén la foto del rostro completamente visible",
          "camera-feedback-barcode": ["Escanea el código de barras"],
          "drop-info": "Suelta la imagen aquí",
          "drop-error":
            "Vaya, no soportamos ese formato de imagen. Por favor, sube un archivo JPEG o PNG.",
          "initialization-error":
            "No se pudo cargar el componente. Intenta usar otro dispositivo o actualizar tu navegador.",
          "process-image-box-first": "Imagen del lado frontal",
          "process-image-box-second": "Imagen del lado posterior",
          "process-image-box-add": "Agregar imagen",
          "process-image-upload-cta": "Subir",
          "process-image-message": "Un momento.",
          "process-image-message-inline": "Procesando",
          "process-image-message-inline-done": "Procesamiento completo",
          "process-api-message": "Un momento",
          "process-api-retry": "Reintentar",
          "feedback-scan-unsuccessful-title": "Escaneo no exitoso",
          "feedback-scan-unsuccessful":
            "No pudimos reconocer tu documento. Por favor, intenta de nuevo.",
          "feedback-error-generic":
            "Vaya, eso no funcionó. Intenta otra vez.",
          "check-internet-connection": "Verifica la conexión a internet.",
          "network-error": "Error de red.",
          "scanning-not-available": "Escaneo no disponible.",
          "modal-window-close": "Cerrar",
        };

        el.current.addEventListener("scanSuccess", (ev) => {
          // console.log(ev.detail);
          let data = ev.detail.recognizer;
          let result = {
            documentNumber: getFirstNonEmptyValue(data.documentNumber),
            firstName: getFirstNonEmptyValue(data.firstName),
            lastName: getFirstNonEmptyValue(data.lastName),
            personalIdNumber: getFirstNonEmptyValue(data.personalIdNumber),
            placeOfBirth: getFirstNonEmptyValue(data.placeOfBirth),
            issuingAuthority: getFirstNonEmptyValue(data.issuingAuthority),
            dateOfBirth: {
              day: data.dateOfBirth.day,
              month: data.dateOfBirth.month,
              year: data.dateOfBirth.year,
              originalString: getFirstNonEmptyValue(
                data.dateOfBirth.originalString
              ),
              successfullyParsed: data.dateOfBirth.successfullyParsed,
              empty: data.dateOfBirth.empty,
            },
            dateOfIssue: {
              day: data.dateOfIssue.day,
              month: data.dateOfIssue.month,
              year: data.dateOfIssue.year,
              originalString: getFirstNonEmptyValue(
                data.dateOfIssue.originalString
              ),
              successfullyParsed: data.dateOfIssue.successfullyParsed,
              empty: data.dateOfIssue.empty,
            },
            dateOfExpiry: {
              day: data.dateOfExpiry.day,
              month: data.dateOfExpiry.month,
              year: data.dateOfExpiry.year,
              originalString: getFirstNonEmptyValue(
                data.dateOfExpiry.originalString
              ),
              successfullyParsed: data.dateOfExpiry.successfullyParsed,
              empty: data.dateOfExpiry.empty,
            },
            country: data.classInfo.countryName,
            countryCode: data.classInfo.isoAlpha2CountryCode,
            sex: getFirstNonEmptyValue(data.sex),
            address: getFirstNonEmptyValue(data.address),
            maritalStatus: getFirstNonEmptyValue(data.maritalStatus),
            nationality: getFirstNonEmptyValue(data.nationality),
            documentType: data.classInfo.documentType,
          };
          // if barcode object is not empty then append barcode to result
          data.barcode && !data.barcode.empty && (result.barcode = data.barcode);
          data.mrz && (result.mrz = data.mrz);

          console.log(JSON.stringify(result));

          let images = {
            frontImage: data.fullDocumentFrontImage,
            frontCameraFrame: data.frontCameraFrame,
            backImage: data.fullDocumentBackImage,
            backCameraFrame: data.backCameraFrame,
            faceImage: data.faceImage,
          };

          setData(result);
          setImages(images);
        });

        el.current.addEventListener("scanError", (ev) => {
          sendMessage("scanError", {
            data: "Error al escanear documento",
            message: "FAILURE",
          });
        });

        el.current.addEventListener("fatalError", (ev) => {
          sendMessage("scanError", {
            data: "Error al escanear documento",
            message: "FAILURE",
          });
        });
      });
       // Modify camera names after the SDK has been initialized
       const interval = setInterval(() => {
        const cameraSelectionComponent = el.current.shadowRoot.querySelector("mb-camera-selection");

        if (cameraSelectionComponent) {
          // Override the cameraList once the component is found
          cameraSelectionComponent.populateCameraDevices = async () => {
            const { frontCameras, backCameras } = await getCameraDevices();

            // Rename front cameras (for display purposes and SDK integration)
            frontCameras.forEach((fCamera) => {
              if (fCamera.label && fCamera.label.startsWith("Front camera")) {
                fCamera.label = fCamera.label.replace(/^Front camera/, "Cámara frontal");
              }
            });

            // Rename back cameras (for display purposes and SDK integration)
            backCameras.forEach((bCamera) => {
              if (bCamera.label && bCamera.label.startsWith("Back camera")) {
                bCamera.label = bCamera.label.replace(/^Back camera/, "Cámara trasera");
              }
            });

            // Combine front and back cameras into a single list
            const modifiedDevices = [...frontCameras, ...backCameras];

            // Update the camera list in the component
            cameraSelectionComponent.cameraList = modifiedDevices;
          };

          // Clear the interval once the component is updated
          clearInterval(interval);
        }
      }, 500);

      // Cleanup the interval when the component unmounts
      return () => clearInterval(interval);
    });
  }, [setData, setImages]);

  return <blinkid-in-browser ref={el}></blinkid-in-browser>;
}

export default DocumentScanner;