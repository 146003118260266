export const sendMessage = (action, payload = {}) => {
  postMessage({ action, payload }, 1, '*');
}

export const postMessage = (message, id = 0, origin) => {
  try {
    const parent = window.opener || window.parent;
    parent.postMessage(JSON.stringify({
      ...message,
      action: `ID-${id}::${message.action}`,
    }), origin);
  } catch (e) {
    console.error("Error: couldn't postMessage to parent window.", e);
  }
}

export const getParams = (urlString) => {
  let params = new URLSearchParams(urlString); //document.location.search
  return params
}

export function isTokenValid(token = {}) {
  const now = Date.now() / 1000;
  const expiry = token.exp;
  return now < expiry;
}

export const blobToFile = (blobObj, fileName) => {
  blobObj.name = fileName;
  blobObj.lastModified = new Date();
  return new File([blobObj], fileName, { type: blobObj.type })
}

export const imageDataToBlob = (imageData) => {
  let w = imageData.width;
  let h = imageData.height;
  let canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;
  let ctx = canvas.getContext("2d");
  ctx.putImageData(imageData, 0, 0);

  return new Promise(resolve => canvas.toBlob(resolve));
};

export async function getIpInfo(resolve, error) {
  try {
  let response = await fetch('https://ipinfo.io/json?token=59a0764fefc5c6');
    if(!response.ok) {
      error();
    }    
    let json = await response.json()
    resolve(json);
  } catch (e) {
    error();
  }
  
}

export const getFirstNonEmptyValue = (obj) => {
  for (const language in obj) {
    if (obj[language] !== "") {
      return obj[language];
    }
  }
  return "";
};